import Vue from 'vue'
import App from './App.vue'
import Buefy from "buefy";
import firebase from "firebase/app";
import "firebase/analytics";
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@mdi/font/css/materialdesignicons.css';
const firebaseConfig = {
  apiKey: "AIzaSyDx2ABeIF29WIVIZXWdhhJpkwRebJE4P2Y",
  authDomain: "tar-laboratory.firebaseapp.com",
  projectId: "tar-laboratory",
  storageBucket: "tar-laboratory.appspot.com",
  messagingSenderId: "729486450241",
  appId: "1:729486450241:web:e769d56fe635992e9030c0",
  measurementId: "G-FYQ75WF6Q4"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.config.productionTip = false;
Vue.use(Buefy, {
  defaultTooltipType: "is-dark",
  defaultTooltipDelay: 500,
  defaultIconPack: 'mdi',
});

new Vue({
  render: h => h(App),
}).$mount('#app')

import { fabric } from "fabric";
import simplifySvgPath from '@/Simplify';
import svgpath from "svgpath";
export type PathData = {
  stroke: string;
  strokeWidth: number;
  path: string;
  index: number;
}
export default class Writer{
  private _canvas: fabric.Canvas;
  private _erase = false;
  constructor(id: string, private viewMode = false){
    this._canvas = new fabric.Canvas(id,{
        isDrawingMode: !viewMode,
        backgroundColor: "rgba(0,0,0,0)",
        targetFindTolerance: 5,
        perPixelTargetFind: true,
        selection: false,
        enableRetinaScaling: true,
        imageSmoothingEnabled: true,
    });
    this.width = 10;
    this._canvas.on("path:created", this.addHandWriting);
    this._canvas.on("mouse:down",(e)=>{
      if(!this._canvas.isDrawingMode && !this.viewMode) this._erase = true;
    });
    this._canvas.on("mouse:up",(e)=>{
      if(!this.viewMode) this._erase = false;
    });
    this._canvas.on("mouse:move",(e)=>{
      if(e.target instanceof fabric.Path && this._erase){
        this._canvas.remove(e.target);
      }
    })
  }
  set width(value: number){
    this._canvas.freeDrawingBrush.width = value;
  }
  set color(value: string){
    this._canvas.freeDrawingBrush.color = value;
  }

  startWrite(){
    this.viewMode = false;
    this._canvas.isDrawingMode = true;
  }
  stopWrite(){
    this.viewMode = true;
    this._canvas.isDrawingMode = false;
  }
  changeDrawMode(value: boolean){
    this._canvas.isDrawingMode = value;
  }

  clear(){
    const objects = this._canvas.getObjects();
    objects.forEach(o=>{
      if(o instanceof fabric.Path)this._canvas.remove(o);
    });
  }

  export(): PathData[]{
    const objects = this._canvas.getObjects();
    const data: PathData[] = [];
    objects.forEach(o=>{
      if(o instanceof fabric.Path && o.path){
        const pathData = o.path
          .map((path) => {
            const arry = Array(path);
            return arry.join(" ");
          })
          .join(" ");
          data.push({
            stroke: o.stroke as string,
            strokeWidth: o.strokeWidth as number,
            path: pathData,
            index: objects.indexOf(o)
          });
      }
    });
    return data;
  }

  pasteDraw(width: number, color: string, path: string){
    const p = new fabric.Path(path,{
      stroke: color,
      strokeWidth: width,
      strokeLineCap: 'round',
      fill: "rgba(0,0,0,0)",
      evented: !this.viewMode,
      selectable: false
    });
    this._canvas.add(p);
  }

  addHandWriting = (e: any)=>{
    const p: fabric.Path | fabric.Point[] | undefined = e.path;
    if (p instanceof fabric.Path && p.path) {
      const pathPoints: any[] = [];
      p.path.forEach(path=>{
        if(path instanceof Array){
          if(path.length === 3){
            const push: number[] = [];
            push.push(path[1] as number);
            push.push(path[2] as number);
            pathPoints.push(push);
          }else if(path.length === 5){
            const push1: number[] = [];
            const push2: number[] = [];
            push1.push(path[1] as number);
            push1.push(path[2] as number);
            pathPoints.push(push1);
            push2.push(path[3] as number);
            push2.push(path[4] as number);
            pathPoints.push(push2);

          }
        }
      });
      const simplePath = simplifySvgPath(pathPoints,{tolerance:3});
      const absPath = svgpath(simplePath)
      .abs()
      .toString();
      const tmpPath = new fabric.Path(absPath);
      p.path = tmpPath.path;
      p.set({
        evented: !this.viewMode,
        selectable: false
      });
    }
  }
  exportImage(): string{
    const str: string = this._canvas.toDataURL({
      format: "png",
      multiplier: 2,
    });
    return str;
  }
  addImage(url: string){
    fabric.Image.fromURL(url,(img: fabric.Image)=>{
      const left = (Number(img.width) - Number(this._canvas.width))/2;
      img.set({
        left: -left,
        selectable: false,
        evented: false
      });
      this._canvas.add(img);
      img.sendToBack();
    });
  }
}








import Vue, { PropType } from "vue";
import Pickr from "@simonwep/pickr";

export default Vue.extend({
  name: "Color",
  props: {
    colorName: String,
    beforeName: String,
    saveOnChange:{
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "#277932"
    },
    position: {
      type: String,
      default: "left-start"
    },
    theme: {
      type: String,
      default: "nano"
    },
    disabled:{
      type: Boolean,
      default: false
    },
    opacity: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Boolean,
      default: true,
    },
    swatches: {
      type: Array as PropType<string[]>,
      default() {
        return [
          "#000000",
          "#FFFFFF",
          //J1
          "#C30000", //sapporo
          "#FCC800", //sendai
          "#9F1636", //kashima
          "#E6002D", //urawa
          "#FFF100", //kashiwa
          "#0B308E", //fc tokyo
          "#E5002D", //fc tokyo
          "#23B7FE", //kawasaki
          "#024098", //yokohama fm
          "#00A0E9", //yokohama fc
          "#6FBA2C", //shonan
          "#FF8709", //shimizu
          "#D80C18", //nagoya
          "#00589F", //g osaka
          "#DA005C", //c osaka
          "#9D031F", //kobe
          "#51318F", //hiroshima
          "#00A1D3", //tosu
          "#EA549A", //tosu
          "#140A8C", //oita
          //J2
          "#003087", //yamagata
          "#FFF338", //yamagata
          "#1D2088", //mito
          "#005CA2", //mito
          "#FFF100", //tochigi
          "#005BAC", //tochigi
          "#FEDC01", //gunma
          "#003B74", //gunma
          "#ED6C00", //omiya
          "#014076", //omiya
          "#D7083B", //chiba
          "#FFE100", //chiba
          "#009260", //chiba
          "#046a38", //tokyo v
          "#a89968", //tokyo v
          "#00226C", //machida
          "#0054A7", //machida
          "#BFAE73", //machida
          "#1933B7", //kofu
          "#B61B19", //kofu
          "#013E1D", //matsumoto
          "#008033", //matsumoto
          "#EA5404", //niigata
          "#014099", //niigata
          "#D70B1E", //kanazawa
          "#8bacd9", //iwata
          "#202f55", //iwata
          "#842078", //kyoto
          "#B6003D", //okayama
          "#232B68", //okayama
          "#EA5F01", //yamaguchi
          "#101184", //tokushima
          "#5BA61C", //tokushima
          "#FF951B", //ehime
          "#01175D", //fukuoka
          "#FCC802", //kitakyushu
          "#E60012", //kitakyushu
          "#F39800", //nagasaki
          "#015BA9", //nagasaki
          "#830035", //ryukyu
          "#E0CD76", //ryukyu
          //J3
          "#008835", //hachinohe
          "#D01019", //morioka
          "#005BAB", //akita
          "#E60012", //fukushima
          "#015BAB", //YSCC
          "#ED7A00", //YSCC
          "#268E42", //sagamihara
          "#EF8301", //nagano
          "#0C277E", //toyama
          "#9C0239", //toyama
          "#803376", //myfc
          "#87B82A", //myfc
          "#009AD7", //numazu
          "#054D22", //gifu
          "#6DBA3D", //tottori
          "#65AADD", //sanuki
          "#363A75", //imabari
          "#FFF101", //imabari
          "#B61913", //kumamoto
          "#B61913", //kumamoto
          "#15335F" //kagoshima
        ];
      }
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
          theme: "nano",
          container: "body",
          components: {
            preview: true,
            opacity: false,
            hue: false,
            interaction: {
              input: false,
              save: true
            }
          }
        };
      }
    }
  },
  data() {
    return {
      selectedColor: null as null | string,
      pckr: null as null | Pickr
    };
  },
  watch: {
    color: function() {
      if (this.pckr !== null) {
        this.pckr.setColor(this.color, true);
        this.pckr.applyColor(true);
        this.selectedColor = this.color;
      }
    },
    disabled: function(value){
      if(value){
        this.pckr?.disable()
      }else{
        this.pckr?.enable()
      }
    }
  },
  mounted() {
    this.selectedColor = this.color;
    const themeDetect: "nano" | "classic" | "monolith" =
      this.swatches.length > 20 ? "classic" : "nano";
    this.pckr = Pickr.create({
      el: this.$refs.pickr as HTMLElement,
      theme: themeDetect,
      disabled: this.disabled,
      container: "body",
      default: this.color,
      swatches: this.swatches as string[],
      position: this.position as
        | "left-start"
        | "top-start"
        | "top-middle"
        | "top-end"
        | "right-start"
        | "right-middle"
        | "right-end"
        | "bottom-start"
        | "bottom-middle"
        | "bottom-end"
        | "left-middle"
        | "left-end"
        | undefined,
      inline: this.inline,
      showAlways: this.inline,
      components: {
        preview: false,
        opacity: this.opacity,
        hue: false,
        interaction: {
          input: false,
          save: this.saveOnChange
        },
      },
    });
    this.pckr.on("save", this.colorSave);
    if(!this.saveOnChange)this.pckr.on("change",this.colorChange);
    this.pckr.on("show", this.show);
    this.pckr.on("hide", this.hide);
    this.pckr.on("swatchselect", ()=>{if(this.pckr)this.pckr.hide()});
  },
  beforeDestroy() {
    if( this.pckr !== null){
      this.pckr.off("save", this.colorSave);
      this.pckr.off("show", this.show);
      this.pckr.off("hide", this.hide);
      if(!this.saveOnChange)this.pckr.off("change",this.colorChange);
      this.pckr.destroyAndRemove();
    }
  },
  methods: {
    colorSave(color: Pickr.HSVaColor, instance: Pickr) {
      this.selectedColor = color.toHEXA().toString();
      this.$emit("color-save", this.selectedColor);
      instance.hide();
      // eslint-disable-next-line
      //@ts-ignore
      const btn = instance.getRoot().button as HTMLElement;
      btn.style.backgroundColor = this.selectedColor;
    },
    colorChange(color: Pickr.HSVaColor){
      this.selectedColor = color.toHEXA().toString();
      this.$emit("color-change", this.selectedColor);
      // eslint-disable-next-line 
      //@ts-ignore
      const btn = this.pckr?.getRoot().button as HTMLElement;
      btn.style.backgroundColor = this.selectedColor;
    },
    show() {
      this.$emit("show");
    },
    hide() {
      this.$emit("hide");
    }
  },
});

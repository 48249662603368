

























































































import Vue from "vue";
import Color from "@/components/Color.vue";
import Writer, { PathData } from "@/Writer";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/firebase-storage";
import download from "downloadjs";
import axios from "axios";
import "hooper/dist/hooper.css";
import { Hooper, Slide } from 'hooper';

export type SaveData = {
  image: string;
  path: PathData[];
};
export type Tweet = {
  text: string;
  id: string;
  time: string;
};
export default Vue.extend({
  name: "App",
  components: {
    Color,
    Hooper,
    Slide
  },
  data() {
    return {
      width: 1200,
      height: 750,
      background: "assets/top.jpg",
      canvas: {} as Writer,
      isLoading: true,
      isWrite: false,
      color: "#f00",
      colors: [] as string[],
      showColor: false,
      brushWidth: 10,
      isMobile: false,
      tweets: [] as Tweet[],
      pathData: [] as PathData[],
      logo: "rgba(13,255,0,.5)",
      img: '',
      showModal:false,
      hooperSettings: {
        itemsToShow: 1,
        vertical: true,
        autoPlay: true,
        playSpeed: 4000,
        transition: 700,
        infiniteScroll: true
      }
    };
  },
  async mounted() {
    axios.get("https://tarlab.jp/tweets.php").then((response) => {
      if (response.data) {
        response.data.forEach((element: any) => {
          // eslint-disable-next-line
          const text = element.text.replace(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,"");
          const id = element.id;
          const time = element.time;
          this.tweets.push({
            text: text,
            id: id,
            time: time,
          });
        });
      }
    });
    let image = "./assets/top.jpg";
    if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)) {
      this.isMobile = true;
      this.width = 422;
    }
    await this.checkData()
      .then((value: SaveData) => {
        this.pathData = value.path;
        if (value.image !== "undefined") image = "images/" + value.image;
      })
      .catch(() => {
        image = "./assets/top.jpg";
      });
    setTimeout(async () => {
      this.canvas = new Writer("app-canvas", true);
      if (image !== "./assets/top.jpg") {
        const ref = firebase.storage().ref(image);
        ref.getDownloadURL().then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = () => {
            const blob = xhr.response;
            const b64 = URL.createObjectURL(blob);
            this.canvas.addImage(b64);
            this.drawPath(this.pathData);
          };
          xhr.open("GET", url);
          xhr.send();
        });
      } else {
        this.canvas.addImage(image);
        this.drawPath(this.pathData);
      }
      this.fitWindow();
      window.addEventListener("resize", this.fitWindow);
    }, 1000);
  },
  methods: {
    drawPath(value: PathData[]) {
      value.forEach((path) => {
        this.canvas.pasteDraw(path.strokeWidth, path.stroke, path.path);
        this.colors.push(path.stroke);
      });
      const simplified = this.colors.filter(function (x, i, self) {
        return self.indexOf(x) === i;
      });
      this.colors = simplified;
      this.color = this.colors[0];
      this.colorChange(this.color);
      this.isLoading = false;
    },
    async checkData(): Promise<SaveData> {
      return new Promise<SaveData>((resolve, reject) => {
        firebase
          .firestore()
          .collection("docs")
          .orderBy("updatedAt", "desc")
          .limit(1)
          .get()
          .then((snapshot) => {
            const data = snapshot.docs[0].data();
            if(Object.prototype.hasOwnProperty.call(data, "logo")){
              this.logo = data.logo;
            }
            if (
              Object.prototype.hasOwnProperty.call(data, "image") &&
              Object.prototype.hasOwnProperty.call(data, "desktop")
            ) {
              resolve({
                image: data.image,
                path: this.isMobile ? data.mobile : data.desktop,
              });
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    fitWindow() {
      const ratio = this.width / this.height;
      const width = window.innerWidth;
      const height = window.innerHeight;
      let scale = 1;
      const container = document.getElementById("container") as HTMLElement;
      const wrapper = document.getElementById("app") as HTMLElement;
      if (!this.isMobile) {
        container.style.left = String(width / 2 - this.width / 2) + "px";
        if (width / height > ratio) {
          scale = width / this.width;
        } else {
          scale = height / this.height;
        }
      } else {
        scale = width / this.width;
        container.style.height = String(this.height * scale) + "px";
        //wrapper.style.height = String(this.height * scale) + 'px';
      }
      container.style.transform = `scale(${scale})`;
    },
    capture() {
      const dataUrl = this.canvas.exportImage();
      const dlLink = document.createElement("a");
      if(!this.isMobile){
        dlLink.href = dataUrl;
      const date = new Date();
      dlLink.download =
          "tarlab_" +
          date.getFullYear() +
          (date.getMonth() + 1) +
          date.getDate() +
          date.getHours() +
          date.getMinutes() +
          '.png';
      dlLink.click();
      }else{
        this.img = dataUrl;
        this.showModal = true;
      }
    },
    clear() {
      this.canvas.clear();
    },
    recover(){
      this.canvas.clear();
      this.drawPath(this.pathData);
    },
    startTag() {
      //this.canvas.clear();
      this.canvas.startWrite();
      this.showColor = true;
    },
    stopTag() {
      this.canvas.stopWrite();
      this.showColor = false;
    },
    colorChange(value: string) {
      this.canvas.color = value;
      this.color = value;
    },
  },
  watch: {
    brushWidth: function (value: number) {
      this.canvas.width = value;
    },
  },
});
